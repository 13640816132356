import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot da react-dom/client
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Lato', sans-serif;
    background-color: #121212;
    color: #ffffff;
    line-height: 1.6;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  input, button {
    font-family: inherit;
  }
`;

const container = document.getElementById('root');
const root = createRoot(container); // Crea il root utilizzando createRoot

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);

reportWebVitals();