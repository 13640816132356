import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Alert, Button, TextField } from '@mui/material';
import axios from 'axios';
import Web3 from 'web3';

const AdminDashboard = () => {
  const [connectedWallets, setConnectedWallets] = useState([]);
  const [activeBots, setActiveBots] = useState([]);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [loadingWallets, setLoadingWallets] = useState(true);
  const [loadingBots, setLoadingBots] = useState(true);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [error, setError] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [account, setAccount] = useState('');
  const [amount, setAmount] = useState('');
  const [privateKey, setPrivateKey] = useState('');

  const checkAuthentication = async () => {
    try {
      const response = await axios.get('/api/check_auth', { withCredentials: true });
      if (response.status === 200 && response.data.authenticated) {
        setAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error("User is not authenticated. Requiring new signature.");
      return false;
    }
    return false;
  };

  const connectMetamask = async () => {
    if (await checkAuthentication()) {
      console.log("User already authenticated");
      return;
    }

    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();

        if (accounts.length === 0) {
          throw new Error("No accounts found. Please ensure MetaMask is unlocked.");
        }

        setAccount(accounts[0]);

        const response = await axios.post('/api/get_nonce', { address: accounts[0] }, { withCredentials: true });
        const nonce = response.data.nonce;

        const signature = await web3.eth.personal.sign(`Sign this message to authenticate: ${nonce}`, accounts[0], '');

        const authResponse = await axios.post('/api/verify_signature', { address: accounts[0], signature }, { withCredentials: true });

        if (authResponse.status === 200) {
          console.log("Authenticated account:", accounts[0]);
          setAuthenticated(true);
          window.location.reload(); // Ricarica la pagina per mantenere la sessione
        } else {
          console.error("Failed to authenticate");
        }
      } catch (error) {
        console.error("Error connecting to MetaMask:", error.message || error);
      }
    } else {
      console.error("MetaMask not found");
    }
  };

  useEffect(() => {
    const fetchConnectedWallets = async () => {
      try {
        const response = await axios.get('/api/admin/connected_wallets');
        setConnectedWallets(response.data);
      } catch (err) {
        setError('Failed to fetch connected wallets');
      } finally {
        setLoadingWallets(false);
      }
    };

    const fetchActiveBots = async () => {
      try {
        const response = await axios.get('/api/admin/active_bots');
        setActiveBots(response.data);
      } catch (err) {
        setError('Failed to fetch active bots');
      } finally {
        setLoadingBots(false);
      }
    };

    const fetchRecentTransactions = async () => {
      try {
        const response = await axios.get('/api/admin/recent_transactions');
        setRecentTransactions(response.data);
      } catch (err) {
        setError('Failed to fetch recent transactions');
      } finally {
        setLoadingTransactions(false);
      }
    };

    if (authenticated) {
      fetchConnectedWallets();
      fetchActiveBots();
      fetchRecentTransactions();
    }
  }, [authenticated]);

  const startBotForUser = async (userId) => {
    try {
      await axios.post(`/api/admin/start_bot/${userId}`, { amount, privateKey });
      alert(`Bot started for user ${userId}`);
    } catch (error) {
      console.error(`Failed to start bot for user ${userId}`, error);
    }
  };

  const stopBotForUser = async (userId) => {
    try {
      await axios.post(`/api/admin/stop_bot/${userId}`);
      alert(`Bot stopped for user ${userId}`);
    } catch (error) {
      console.error(`Failed to stop bot for user ${userId}`, error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {!authenticated ? (
        <Button variant="contained" color="primary" onClick={connectMetamask}>
          Connect MetaMask to Access Admin Dashboard
        </Button>
      ) : (
        <>
          {error && <Alert severity="error">{error}</Alert>}
          <Grid container spacing={3}>
            {/* Connected Wallets */}
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">Connected Wallets</Typography>
                {loadingWallets ? <CircularProgress /> : (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Wallet Address</TableCell>
                        <TableCell>Connected Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {connectedWallets.map((wallet, index) => (
                        <TableRow key={index}>
                          <TableCell>{wallet.address}</TableCell>
                          <TableCell>{new Date(wallet.connected_time).toLocaleString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Paper>
            </Grid>

            {/* Active Bots */}
            <Grid item xs={12} md={8}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">Active Bots</Typography>
                {loadingBots ? <CircularProgress /> : (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Bot ID</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeBots.map((bot, index) => (
                        <TableRow key={index}>
                          <TableCell>{bot.id}</TableCell>
                          <TableCell>{bot.user}</TableCell>
                          <TableCell>{bot.status}</TableCell>
                          <TableCell>
                            <TextField
                              label="Amount"
                              variant="outlined"
                              size="small"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                              style={{ marginRight: 8 }}
                            />
                            <TextField
                              label="Private Key"
                              variant="outlined"
                              size="small"
                              value={privateKey}
                              onChange={(e) => setPrivateKey(e.target.value)}
                              style={{ marginRight: 8 }}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => startBotForUser(bot.user_id)}
                              style={{ marginRight: 8 }}
                            >
                              Start
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => stopBotForUser(bot.user_id)}
                            >
                              Stop
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Paper>
            </Grid>

            {/* Recent Transactions */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">Recent Transactions</Typography>
                {loadingTransactions ? <CircularProgress /> : (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Transaction ID</TableCell>
                        <TableCell>Bot ID</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recentTransactions.map((tx, index) => (
                        <TableRow key={index}>
                          <TableCell>{tx.id}</TableCell>
                          <TableCell>{tx.bot_id}</TableCell>
                          <TableCell>{tx.amount}</TableCell>
                          <TableCell>{tx.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default AdminDashboard;
