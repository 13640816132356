import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Web3 from 'web3';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0e0e0f; /* Sfondo scuro */
  color: #eaeaea; /* Testo chiaro */
  font-family: 'Inter', sans-serif; /* Font pulito e moderno */
`;

const WalletInfo = styled.div`
  margin-top: 20px;
  color: #29b6f6; /* Colore di accento azzurro */
`;

const BotContainer = styled.div`
  background-color: #1b1c1d; /* Contenitore scuro */
  padding: 30px;
  border-radius: 12px;
  margin-top: 30px;
  width: 80%;
  max-width: 700px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Leggera ombra */
`;

const Input = styled.input`
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
  background-color: #2a2b2d; /* Sfondo input scuro */
  color: #eaeaea; /* Testo chiaro */
  border: 1px solid #333; /* Bordo sottile */
  border-radius: 8px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: #29b6f6; /* Bordo in focus */
  }
`;

const Button = styled.button`
  background-color: #29b6f6; /* Colore di accento */
  color: #0e0e0f; /* Colore testo su pulsanti */
  border: none;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 8px;
  font-weight: 600;
  &:hover {
    background-color: #1e88e5;
  }
  &:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
`;

const TransactionTable = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #2a2b2d;
  color: #eaeaea;
  padding: 10px;
  font-weight: 600;
`;

const TableData = styled.td`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #333;
  color: #eaeaea;
`;

const Dashboard = () => {
  const [account, setAccount] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [amount, setAmount] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [isBotRunning, setIsBotRunning] = useState(false);

  const checkAuthentication = async () => {
    try {
      const response = await axios.get('/api/check_auth', { withCredentials: true });
      if (response.status === 200 && response.data.authenticated) {
        setAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error("User is not authenticated. Requiring new signature.");
      return false;
    }
    return false;
  };

  const connectMetamask = async () => {
    if (await checkAuthentication()) {
      console.log("User already authenticated");
      return;
    }

    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();

        if (accounts.length === 0) {
          throw new Error("No accounts found. Please ensure MetaMask is unlocked.");
        }

        setAccount(accounts[0]);

        const response = await axios.post('/api/get_nonce', { address: accounts[0] }, { withCredentials: true });
        const nonce = response.data.nonce;

        console.log('Nonce:', nonce);
        console.log('Account:', accounts[0]);

        const signature = await web3.eth.personal.sign(`Sign this message to authenticate: ${nonce}`, accounts[0], '');

        const authResponse = await axios.post('/api/verify_signature', { address: accounts[0], signature }, { withCredentials: true });

        if (authResponse.status === 200) {
          console.log("Authenticated account:", accounts[0]);
          setAuthenticated(true);
          window.location.reload(); // Ricarica la pagina per mantenere la sessione
        } else {
          console.error("Failed to authenticate");
        }
      } catch (error) {
        console.error("Error connecting to MetaMask:", error.message || error);
      }
    } else {
      console.error("MetaMask not found");
    }
  };

  const startBot = async () => {
    if (!amount || !privateKey) {
      alert("Please enter both amount and private key.");
      return;
    }

    try {
      await axios.post('/api/start_bot', { amount, privateKey }, { withCredentials: true });
      alert("Bot started successfully.");
      setIsBotRunning(true);
    } catch (error) {
      console.error("Error starting bot:", error);
      alert("Failed to start bot.");
    }
  };

  const stopBot = async () => {
    try {
      await axios.post('/api/stop_bot', {}, { withCredentials: true });
      alert("Bot stopped successfully.");
      setIsBotRunning(false);
    } catch (error) {
      console.error("Error stopping bot:", error);
      alert("Failed to stop bot.");
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('/api/get_transactions', { withCredentials: true });
        setTransactions(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("User is not authenticated. Redirecting to login...");
        } else {
          console.error("Error fetching transactions:", error);
        }
      }
    };

    if (authenticated) {
      fetchTransactions();
      const interval = setInterval(fetchTransactions, 10000);
      return () => clearInterval(interval);
    }
  }, [authenticated]);

  return (
    <DashboardContainer>
      <h2>Dashboard</h2>
      {authenticated ? (
        <WalletInfo>Connected and authenticated account: {account}</WalletInfo>
      ) : (
        <Button onClick={connectMetamask}>Connect Metamask</Button>
      )}

      {authenticated && (
        <BotContainer>
          <h3>Bot Control</h3>
          <Input 
            type="number" 
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            disabled={isBotRunning}
          />
          <Input 
            type="password" 
            placeholder="Private Key" 
            value={privateKey} 
            onChange={(e) => setPrivateKey(e.target.value)}
            disabled={isBotRunning}
          />
          <Button onClick={startBot} disabled={isBotRunning}>Start Bot</Button>
          <Button onClick={stopBot} style={{ marginLeft: '10px' }} disabled={!isBotRunning}>Stop Bot</Button>
          <h3>Transactions</h3>
          <TransactionTable>
            <thead>
              <tr>
                <TableHeader>ID</TableHeader>
                <TableHeader>Amount</TableHeader>
                <TableHeader>Status</TableHeader>
              </tr>
            </thead>
            <tbody>
              {transactions.map((tx, index) => (
                <tr key={index}>
                  <TableData>{tx.id}</TableData>
                  <TableData>{tx.amount}</TableData>
                  <TableData>{tx.status}</TableData>
                </tr>
              ))}
            </tbody>
          </TransactionTable>
        </BotContainer>
      )}
    </DashboardContainer>
  );
};

export default Dashboard;